<template>
  <v-footer>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        elevation="0"
        color="#18739d"
        text
        dark
        link
        :target="!link.mailto ? '_blank' : ''"
        :href="link.link"
        :ripple="false"
        rounded
        class="my-2 rounded-0"
      >
        {{ link.title }}
      </v-btn>
      <v-col
        class="py-4 text-center black--text font-weight-light subtitle-2"
        cols="12"
      >
        © Copyright {{ currentYear }} Privately - All Rights Reserved
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
      links: [
        {
          title: "Privacy Policy",
          link: "https://www.privately.eu/legal",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:dev_channel_contact@privately.eu?subject=General Inquiry about Privately Developer Hub",
          mailto: true,
        },
      ],
    };
  },
};
</script>

