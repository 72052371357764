var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"current":"userApproval"}},[_c('v-container',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card',[_c('v-card-title',[_vm._v(" User List "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.userArray,"sort-by":['approval_status'],"sort-desc":[false],"items-per-page":20,"custom-sort":_vm.customSort,"search":_vm.search,"headers":_vm.headers,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.preferred_products",fn:function(ref){
var item = ref.item;
return _vm._l((item.preferred_products),function(product){return _c('v-chip',{key:product,staticClass:"ma-1",attrs:{"x-small":"","pill":""}},[_vm._v(" "+_vm._s(product)+" ")])})}},{key:"item.submitted_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.submitted_at ? _vm.formatDateTime(item.submitted_at) : ""))])]}},{key:"item.industry",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.industry === "Other" ? item.industry_other ? item.industry_other : "Other" : item.industry))])]}},{key:"item.job_title",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.job_title == "Other" ? item.job_title_other ? item.job_title_other : "Other" : item.job_title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(!item.approval_status)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.showConfirmationDialog('approved', item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check ")])],1):_vm._e(),(!item.approval_status)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.showConfirmationDialog('rejected', item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cancel ")])],1):_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.approval_status))])],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmation")]),_c('v-card-text',[_vm._v(" Are you sure you want to "+_vm._s(_vm.action == "approved" ? "approve" : "reject")+" this user? ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"text-capitalize rounded-pill",attrs:{"color":"primary"},on:{"click":_vm.cancelAction}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize rounded-pill",attrs:{"color":"success"},on:{"click":_vm.confirmAction}},[_vm._v("Confirm")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }