var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('retail-base-layout',{attrs:{"current":"manageSubscriptions"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-main",attrs:{"elevation":"0","loading":_vm.isLoading}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Billing Group ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Payment Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Last Update")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.billingGroups),function(item){return _c('tr',{key:item.billing_group_id},[_c('td',[_vm._v(_vm._s(item.billing_group_id))]),_c('td',[_vm._v(" "+_vm._s(item.payment_info.payment_result ? item.payment_info.payment_result : "payment_not_made")+" ")]),_c('td',[(item.payment_info && item.payment_info.updated_at)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.payment_info.updated_at))+" ")]):_vm._e()]),_c('td',[(
                    item.payment_info.payment_result &&
                    item.payment_info.payment_result ===
                      'payment_succeeded' &&
                    item.payment_info.stripe_customer_id &&
                    item.payment_info.stripe_subscription_id
                  )?_c('v-btn',{attrs:{"loading":_vm.isCancelLoading},on:{"click":function($event){return _vm.cancelSubscription(
                      item.payment_info.stripe_subscription_id
                    )}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v("Cancel Subscription")]):_c('div')],1)])}),0)]},proxy:true}])}),_c('v-snackbar',{attrs:{"timeout":"2000","absolute":"","right":"","color":"green","text":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }