<template>
  <v-app id="inspire">
    <v-container class="py-8">
      <v-row justify="center" align="center">
        <v-col md="10" lg="8" xl="6" xs="12" sm="12">
          <img :src="require('@/assets/images/privlogo.png')" alt="logo" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col md="10" lg="8" xl="6" xs="12" sm="12">
          <span class="text-h4">Sign Up for a 30 Day Free Trial!</span>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="isSubmitted">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12"
          ><v-alert outlined text type="success"
            >Your application is under review. Thank you for your patience as we
            assess your details. Further information will be emailed to
            <span class="subtitle-2">{{ tempEmail }}</span
            >.
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="hasError">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12"
          ><v-alert outlined text type="error"
            >The email you entered is already registered in the system!
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="!isSubmitted">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12">
          <span class="caption">All fields are mandatory</span>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid">
        <v-row justify="center">
          <v-col no-gutters cols="12" md="5" lg="4" xl="3" xs="12" sm="6">
            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="1"
              outlined
              class="rounded-0"
              v-model="firstName"
              :rules="rules"
              label="First Name"
              required
            ></v-text-field> </v-col
          ><v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6">
            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="2"
              outlined
              class="rounded-0"
              v-model="lastName"
              :rules="rules"
              label="Last Name"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6">
            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="3"
              outlined
              class="rounded-0"
              v-model="email"
              :rules="emailRules"
              label="Work E-mail"
              required
            ></v-text-field></v-col
          ><v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6">
            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="4"
              outlined
              class="rounded-0"
              v-model="company"
              :rules="rules"
              label="Company"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6">
            <v-select
              dense
              v-if="!showJobTitleOther"
              v-on:change="onChangeJobTitle(jobTitle)"
              background-color="#FAFAFA"
              class="rounded-0"
              :items="jobTitleList"
              label="Job Title"
              v-model="jobTitle"
              outlined
              required
              :rules="rules"
            ></v-select>
            <v-text-field
              v-else
              dense
              append-icon="mdi-close-circle"
              @click:append="
                showJobTitleOther = !showJobTitleOther;
                jobTitle = null;
                jobTitleOther = null;
              "
              background-color="#FAFAFA"
              tabindex="4"
              outlined
              class="rounded-0"
              v-model="jobTitleOther"
              :rules="rules"
              label="Job Title - Please Specify"
              required
            ></v-text-field> </v-col
          ><v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6"
            ><v-select
              v-if="!showIndustryOther"
              dense
              v-on:change="onChangeIndustry(industry)"
              background-color="#FAFAFA"
              class="rounded-0"
              :items="industryList"
              label="Industry"
              v-model="industry"
              outlined
              required
              :rules="rules"
            ></v-select>
            <v-text-field
              v-else
              dense
              append-icon="mdi-close-circle"
              @click:append="
                showIndustryOther = !showIndustryOther;
                industry = null;
                industryOther = null;
              "
              background-color="#FAFAFA"
              tabindex="4"
              outlined
              class="rounded-0"
              v-model="industryOther"
              :rules="rules"
              label="Industry - Please Specify"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6">
            <v-select
              background-color="#FAFAFA"
              class="rounded-0"
              v-model="preferredProducts"
              :items="formattedProductList"
              label="Preferred Products"
              :menu-props="{ maxHeight: '500' }"
              multiple
              outlined
              required
              dense
              :rules="[
                (v) =>
                  (!!v && v.length != 0) ||
                  'Please select at least one product',
              ]"
            ></v-select></v-col
          ><v-col cols="12" md="5" lg="4" xl="3" xs="12" sm="6"></v-col>
        </v-row>

        <v-row justify="center">
          <v-col xl="6" lg="8" md="10" xs="12" sm="12">
            <v-checkbox
              v-model="checkbox"
              label="Yes, I'd like Privately to stay in touch with me by email to provide important information about Privately products and other offers."
              required
            ></v-checkbox
          ></v-col>
        </v-row>

        <v-row justify="center">
          <v-col xl="6" lg="8" md="10" xs="12" sm="12">
            <v-btn
              style="text-transform: unset !important"
              x-large
              color="#18739d"
              class="mr-4 white--text"
              elevation="0"
              @click="submit"
              :ripple="false"
              :loading="loading"
            >
              Sign Up Now
            </v-btn></v-col
          >
        </v-row>
        <v-row justify="center">
          <v-col xl="6" lg="8" md="10" xs="12" sm="12">
            <span class="caption"
              >By signing up for an account, you agree to our
              <b>Terms and Conditions</b>. We'll hold and process your details
              in accordance with our <b>Privacy Policy</b></span
            ></v-col
          >
        </v-row>
      </v-form>
      <br />
    </v-container>

    <FooterItem />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import FooterItem from "@/components/FooterItem.vue";

export default {
  components: { FooterItem },
  computed: {
    formattedProductList() {
      const fmt = [
        { disabled: true, header: "Age Assurance" },
        ...this.preferredProductList.slice(0, 2).map((text) => ({ text })),
        { disabled: true, header: "Online Safety" },
        ...this.preferredProductList.slice(2).map((text) => ({ text })),
      ];

      return fmt;
    },
  },
  data: () => ({
    showIcon: false,
    loading: false,
    isSubmitted: false,
    hasError: false,
    valid: false,
    firstName: "",
    lastName: "",
    email: "",
    jobTitleList: [
      "Developer",
      "Tech Executive",
      "Architect / IT",
      "Product / Project Manager",
      "Business Executive",
      "Startup Founder",
      "Student",
      "Other",
    ],
    preferredProductList: [
      "FaceAssure",
      "VoiceAssure",
      "OWAS Image SDKs",
      "OWAS NLP SDKs",
      "OWAS CSAM SDKs",
    ],
    industryList: [
      "Gaming",
      "Retail",
      "Gambling",
      "Social Platforms",
      "Embedded In-Device Technology",
      "Other",
    ],
    // password: "",
    // passwordRules: [
    //   (value) => !!value || "Please enter a password",
    //   (value) => (value && /\d/.test(value)) || "At least one digit",
    //   (value) =>
    //     (value && /[A-Z]{1}/.test(value)) || "At least one capital latter",
    //   (value) =>
    //     (value && /[^A-Za-z0-9]/.test(value)) ||
    //     "At least one special character",
    //   (value) => (value && value.length >= 8) || "minimum 8 characters",
    // ],
    company: "",
    jobTitle: "",
    industry: "",
    jobTitleOther: null,
    industryOther: null,
    showJobTitleOther: false,
    showIndustryOther: false,
    preferredProducts: [],
    baseDomain: window.location.origin,
    checkbox: false,
    login_route: "/login",
    tempEmail: "",
    rules: [(v) => !!v || "This field is required"],
    emailRules: [
      (v) => !!v || "This field is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) =>
        /^(?!.*@(gmail\.com|hotmail\.com|yahoo\.com|outlook\.com|aol\.com|icloud\.com|protonmail\.com|mail\.com|zoho\.com|yandex\.com|live\.com|gmx\.com|mailinator\.com|inbox\.lv|fastmail\.com|yopmail\.com|gmx\.us|rocketmail\.com|dispostable\.com|guerrillamail\.com|10mail\.org|mailnesia\.com|throwawaymail\.com|tempmail\.net|maildrop\.cc|mailinator2\.com|anonbox\.net|mailnesia\.com|guerrillamailblock\.com|getnada\.com|emlhub\.com|cock\.li|trash-mail\.com|tempmail\.ru|tutanota\.com|trbvm\.com|kurzepost\.de)).*$/.test(
          v
        ) || "Work email is required",
    ],
    select: null,
    links: [
      {
        title: "Privacy Policy",
        link: "/static/docs/privacy_policy.pdf",
      },
      {
        title: "Terms and Conditions",
        link: "/static/docs/terms_and_conditions.pdf",
      },
      {
        title: "Contact Us",
        link: "mailto:dev_channel_contact@privately.eu?subject=General Inquiry about Privately Developer Hub",
        mailto: true,
      },
    ],
  }),
  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    ...mapActions(["SignUp", "DevSignUp"]),

    receiveMessage(event) {
      if (event.data == "success") {
        this.isVerificationSuccessful = true;
      }
    },

    onChangeJobTitle(e) {
      this.showJobTitleOther = e === "Other";
    },
    onChangeIndustry(e) {
      this.showIndustryOther = e === "Other";
    },
    async submit() {
      this.isSubmitted = false;
      this.hasError = false;
      this.validate();
      this.loading = true;
      if (this.valid) {
        this.tempEmail = this.email;
        let payload = {
          email: this.email,
          registration_details: {
            company: this.company,
            name: this.firstName + " " + this.lastName,
            job_title: this.jobTitle,
            industry: this.industry,
            preferred_products: this.preferredProducts,
            subscribe_news: this.checkbox,
            submitted_at: Date.now(),
            job_title_other: this.jobTitleOther,
            industry_other: this.industryOther,
          },
        };
        try {
          this.DevSignUp(payload)
            .then((res) => {
              if (res.result == "OK") {
                this.isSubmitted = true;
                this.loading = false;
                this.reset();
                window.scrollTo(0, 0);
              }
            })
            .catch((e) => {
              console.log(e);
              this.loading = false;
              this.hasError = true;
            });
        } catch (err) {
          console.log("err registering: ", err);
          this.loading = false;
        }

        console.log(payload);
      } else {
        this.loading = false;
      }
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>