<template>
  <v-app>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col md="6"
          ><v-alert :value="true" type="success" style="text-align: center"
            >Thank you, your payment was successful!</v-alert
          >
          <v-btn
            color="#18739d"
            dark
            style="text-transform: unset !important"
            block
            to="/dashboard"
            ><v-icon>mdi-arrow-left</v-icon> Back to Dashboard</v-btn
          >
        </v-col>
      </v-row>
    </v-container></v-app
  >
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import axios from "axios";

export default {
  components: {},
  name: "PaymentSuccess",
  methods: {
    ...mapActions(["UpdatePlan", "UpdatePaymentSession", "GetCurrentPlan"]),
    async updatePlan() {
      if (store.getters.StateIsPaymentSessionActive) {
        let query = this.$route.query;
        let sessionUpdatePayload = "";
        console.log(query);
        try {
          axios
            .get(
              "https://developer.privately.eu/api/v1/order/success?session_id=" +
                query.session_id
            )
            .then((resp) => {
              console.log(resp.data);
              sessionUpdatePayload = {
                sessionId: query.sessionId,
                selectedPlan: query.selectedPlan,
                stripe_customer_id: resp.data.stripeCustomerDetails.id,
                stripe_subscription_id:
                  resp.data.stripeSessionDetails.subscription,
              };

              console.log("awaiting");
              console.log("sessionUpdatePayload", sessionUpdatePayload);
              this.UpdatePlan(sessionUpdatePayload);
              this.GetCurrentPlan(store.getters.StateSessionId);
              this.UpdatePaymentSession(false);
            });
        } catch (error) {
          console.log(error);
          this.$router.push("/dashboard");
        }
      } else {
        this.$router.push("/dashboard");
      }
    },
  },
  data() {
    return {};
  },
  beforeMount() {
    this.updatePlan();
  },
};
</script>
