<template>
  <base-layout current="dashboard">
    <v-container fluid>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="#18739d"
      ></v-progress-linear>
      <v-card
        class="card-main"
        elevation="0"
        :height="$vuetify.breakpoint.mdAndDown ? '100%' : ''"
        ><v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab key="ageassurance" class="reset-text-transform">
            Age Assurance
          </v-tab>
          <v-tab key="onlinesafety" class="reset-text-transform">
            Online Safety
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="py-2">
          <v-tab-item key="ageassurance"
            ><v-row justify="center" align="center">
              <v-col>
                <v-row justify="center">
                  <v-icon
                    color="#18739d"
                    large
                    class="justify-center"
                    @click="
                      getEstimations(selectedDate.subtract(1, 'M'));
                      dateMP = selectedDate.format('MMMM YYYY');
                    "
                    >mdi-chevron-left</v-icon
                  >
                </v-row>
              </v-col>
              <v-col xl="2" lg="2" md="3" sm="2" xs="2">
                <v-menu
                  ref="menuMP"
                  v-model="menuMP"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="dateMP2"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      text
                      style="width: 165px"
                      v-model="dateMP"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateMP2"
                    type="month"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuMP = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menuMP.save(dateMP2);
                        dateMP = moment(dateMP2).format('MMMM YYYY');
                        getEstimations(moment(dateMP));
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-row justify="center"
                  ><v-icon
                    @click="
                      getEstimations(selectedDate.add(1, 'M'));
                      dateMP = selectedDate.format('MMMM YYYY');
                    "
                    color="#18739d"
                    large
                    >mdi-chevron-right</v-icon
                  ></v-row
                ></v-col
              >
            </v-row>
            <v-container v-if="showData">
              <v-row>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  xs="12"
                  sm="12"
                  v-if="showData"
                >
                  <EstimationCard
                    :metric-title="metricData.estimationsThisMonth.metricTitle"
                    :metricValue="metricData.estimationsThisMonth.metricValue"
                    :hasRisen="metricData.estimationsThisMonth.hasRisen"
                    :metricPercentage="
                      metricData.estimationsThisMonth.metricPercentage
                    "
                    :loading="loading"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" xs="12" sm="12">
                  <EstimationCard
                    :metricTitle="
                      metricData.averageEstimationsPerDay.metricTitle
                    "
                    :metricValue="
                      metricData.averageEstimationsPerDay.metricValue
                    "
                    :hasRisen="metricData.averageEstimationsPerDay.hasRisen"
                    :metricPercentage="
                      metricData.averageEstimationsPerDay.metricPercentage
                    "
                    :loading="loading"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" xs="12" sm="12">
                  <EstimationCard
                    :metricTitle="metricData.totalSpoofDetections.metricTitle"
                    :metricValue="metricData.totalSpoofDetections.metricValue"
                    :hasRisen="metricData.totalSpoofDetections.hasRisen"
                    :metricPercentage="
                      metricData.totalSpoofDetections.metricPercentage
                    "
                    :loading="loading"
                  />
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="10">
                  <v-divider
                    class="mt-8"
                    style="border-top: dotted 1px; color: #cecece"
                    light
                  ></v-divider></v-col
              ></v-row>

              <br />

              <v-row class="mt-8" justify="center">
                <v-col cols="10">
                  <v-row
                    ><span class="subtitle-2"
                      >Age Distribution (years)</span
                    ></v-row
                  >
                  <v-row>
                    <v-col cols="12" xl="6" lg="6" md="6" xs="12" sm="12">
                      <PieChart
                        :class="
                          $vuetify.breakpoint.mdAndDown
                            ? 'pie-chart-mobile'
                            : 'pie-chart-desktop'
                        "
                        :chart-data="chartData"
                      ></PieChart>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" xs="12" sm="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Average Time Taken</v-list-item-title
                          >
                          <v-list-item-subtitle>(sec)</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn icon>
                            <v-menu open-on-hover top offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="#18739d"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span>
                                    {{
                                      metricData.latencyStats.average.toFixed(2)
                                    }}</span
                                  >
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item>
                                  <span class="subtitle-2">Minimum: </span>
                                  {{
                                    metricData.latencyStats.minimum.toFixed(2)
                                  }}
                                </v-list-item>
                                <v-list-item>
                                  <span class="subtitle-2">Maximum: </span>
                                  {{
                                    metricData.latencyStats.maximum.toFixed(2)
                                  }}
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4"><span></span></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <NoData v-else />
          </v-tab-item>
          <v-tab-item key="onlinesafety">
            <v-row justify="center" align="center">
              <v-col>
                <v-row justify="center">
                  <v-icon
                    color="#18739d"
                    large
                    class="justify-center"
                    @click="
                      getEstimations_owas(
                        selectedDate_owas.subtract(1, 'M'),
                        selectedModality
                      );
                      dateMP_owas = selectedDate_owas.format('MMMM YYYY');
                    "
                    >mdi-chevron-left</v-icon
                  >
                </v-row>
              </v-col>
              <v-col xl="2" lg="2" md="3" sm="2" xs="2">
                <v-menu
                  ref="menuMP_owas"
                  v-model="menuMP_owas"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="dateMP2_owas"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      text
                      style="width: 165px"
                      v-model="dateMP_owas"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateMP2_owas"
                    type="month"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuMP_owas = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menuMP_owas.save(dateMP2_owas);
                        dateMP_owas = moment(dateMP2_owas).format('MMMM YYYY');
                        getEstimations_owas(
                          moment(dateMP_owas),
                          selectedModality
                        );
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-row justify="center"
                  ><v-icon
                    @click="
                      getEstimations_owas(
                        selectedDate_owas.add(1, 'M'),
                        selectedModality
                      );
                      dateMP_owas = selectedDate_owas.format('MMMM YYYY');
                    "
                    color="#18739d"
                    large
                    >mdi-chevron-right</v-icon
                  ></v-row
                ></v-col
              >
            </v-row>
            <div v-if="showData_owas">
              <v-row>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  xs="12"
                  sm="12"
                  v-if="true"
                >
                  <EstimationCard
                    :metric-title="
                      owas_titles[selectedModality] +
                      ' ' +
                      metricData.estimationsThisMonth.metricTitle
                    "
                    :metricValue="allTransactionCount"
                    :hasRisen="metricData.estimationsThisMonth.hasRisen"
                    :metricPercentage="
                      metricData.estimationsThisMonth.metricPercentage
                    "
                    :loading="loading"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10">
                  <v-divider
                    class="mt-8"
                    style="border-top: dotted 1px; color: #cecece"
                    light
                  ></v-divider></v-col
              ></v-row>
            </div>

            <br />
            <v-card
              :loading="loading"
              class="d-flex flex-column"
              color="transparent"
              elevation="0"
            >
              <div v-if="showData_owas">
                <v-tabs-items v-model="owas_tab">
                  <v-tab-item key="image">
                    <v-row class="justify-center align-center">
                      <v-col cols="4">
                        <div id="chart">
                          <VueApexCharts
                            type="bar"
                            height="350"
                            :options="image_chartOptions"
                            :series="image_series"
                          ></VueApexCharts>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="nlp">
                    <v-tabs vertical class="pa-10">
                      <v-tab class="reset-text-transform">
                        <v-icon left> mdi-chart-bar-stacked </v-icon>
                        Analytics Data
                      </v-tab>
                      <v-tab class="reset-text-transform">
                        <v-icon left> mdi-chart-line </v-icon>
                        Timeseries Data
                      </v-tab>

                      <v-tab-item>
                        <v-row class="justify-center align-center">
                          <v-col xs="12" sm="12" md="10">
                            <div id="chart">
                              <VueApexCharts
                                type="bar"
                                height="350"
                                :options="nlp_chartOptions"
                                :series="nlp_series"
                              ></VueApexCharts>
                            </div>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item>
                        <v-row class="justify-center align-center">
                          <v-col xs="12" sm="12" md="10">
                            <div id="chart">
                              <VueApexCharts
                                type="bar"
                                height="350"
                                :options="nlp_timeseries_chartOptions"
                                :series="nlp_timeseries_series"
                              ></VueApexCharts>
                            </div>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item></v-tab-item>
                    </v-tabs>
                  </v-tab-item>
                  <v-tab-item key="csam">
                    <v-row class="justify-center align-center">
                      <v-col cols="4">
                        <div id="chart">
                          <VueApexCharts
                            type="bar"
                            height="350"
                            :options="csam_chartOptions"
                            :series="csam_series"
                          ></VueApexCharts>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <div style="min-height: 350px" v-else>
                <NoData />
              </div>

              <v-card-actions>
                <v-tabs v-model="owas_tab" background-color="transparent" grow>
                  <v-tab
                    class="reset-text-transform"
                    key="image"
                    @click="
                      selectedModality = 'image';
                      getEstimations_owas(moment(dateMP_owas), 'image');
                    "
                  >
                    Image
                  </v-tab>
                  <v-tab
                    key="nlp"
                    @click="
                      selectedModality = 'nlp';
                      getEstimations_owas(moment(dateMP_owas), 'nlp');
                    "
                  >
                    NLP
                  </v-tab>
                  <v-tab
                    key="csam"
                    @click="
                      selectedModality = 'csam';
                      getEstimations_owas(moment(dateMP_owas), 'csam');
                    "
                  >
                    CSAM
                  </v-tab>
                </v-tabs>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import EstimationCard from "@/components/cards/EstimationCard.vue";
import NoData from "@/components/cards/NoData.vue";
import PieChart from "@/components/charts/PieChart.js";
import store from "@/store";

import moment from "moment";
import { mapActions } from "vuex";
import "chartjs-plugin-labels";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    BaseLayout,
    PieChart,
    EstimationCard,
    NoData,
    VueApexCharts,
  },
  beforeMount() {
    this.getEstimations(moment());
    this.getEstimations_owas(moment(), this.selectedModality);
  },
  computed: {
    pieChartSize() {
      return this.$vuetify.breakpoint.smAndDown ? "250" : "300";
    },
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {
      this.visible = false;
    },
    disabledDate(current) {
      return current && current >= moment().endOf("day");
    },
    calculateAverageEstimations(count) {
      let todayDay = moment().date();
      let todayMonth = moment().month() + 1;
      let todayYear = moment().year();
      if (this.selectedMonth == todayMonth && this.selectedYear == todayYear) {
        return count / todayDay;
      }
      return count / 30;
    },
    hasRisen(percentage) {
      if (percentage == 0) return "minus";

      return percentage > 0 ? "rise" : "fall";
    },
    showLoading() {
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },
    moment,
    ...mapActions(["GetEstimations", "GetEstimations_owas"]),
    async getEstimations(event) {
      console.log("event", event);
      this.selectedDate = event;
      this.selectedYear = event.year();
      this.selectedMonth = event.month() + 1;
      let payload = {
        sessionId: store.getters.StateSessionId,
        year: event.year(),
        month: event.month() + 1,
      };
      try {
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        this.lineChartData.labels = [];
        this.lineChartData.datasets[0].data = [];
        this.showLoading();
        await this.GetEstimations(payload).then((response) => {
          if (response) {
            let i = 0;
            let j = 0;

            for (var key in response.age_distributions) {
              this.chartData.labels[j++] = key;
              this.chartData.datasets[0].data[i++] =
                response.age_distributions[key];
            }
            //required for re-rendering
            this.chartData = Object.assign({}, this.chartData, {
              datasets: this.chartData.datasets,
              labels: this.chartData.labels,
            });

            let numOfDaysInMonth =
              event.year() == moment().year() &&
              event.month() == moment().month()
                ? event.date()
                : event.daysInMonth();

            for (let i = 1; i <= numOfDaysInMonth; i++) {
              this.lineChartData.labels.push(i);
            }

            this.lineChartData.datasets.data = new Array(numOfDaysInMonth);
            for (let j = 0; j < numOfDaysInMonth; ++j)
              this.lineChartData.datasets[0].data[j] = 0;

            for (let k = 0; k < numOfDaysInMonth; k++) {
              for (var key2 in response.timeseries) {
                if (k == key2 - 1) {
                  this.lineChartData.datasets[0].data[k] =
                    response.timeseries[key2];
                }
              }
            }

            this.lineChartData = Object.assign({}, this.lineChartData, {
              datasets: this.lineChartData.datasets,
              labels: this.lineChartData.labels,
            });

            //estimations this month
            this.metricData.estimationsThisMonth.metricValue = response.count;
            this.metricData.estimationsThisMonth.metricPercentage = Math.abs(
              response.change_percentage
            );
            this.metricData.estimationsThisMonth.hasRisen = this.hasRisen(
              response.change_percentage
            );
            // TODO avg estimations per day
            this.metricData.averageEstimationsPerDay.metricValue =
              this.calculateAverageEstimations(response.count).toFixed(2);
            this.metricData.averageEstimationsPerDay.metricPercentage =
              Math.abs(response.change_percentage);
            this.metricData.averageEstimationsPerDay.hasRisen = this.hasRisen(
              response.change_percentage
            );

            this.metricData.latencyStats = response.latency_statistics;

            // TODO total spoof detections
            this.metricData.totalSpoofDetections.metricValue =
              response.spoof_count;
            this.metricData.totalSpoofDetections.metricPercentage = Math.abs(
              response.change_percentage
            );
            this.metricData.totalSpoofDetections.hasRisen = this.hasRisen(
              response.change_percentage
            );
            this.showData = response.count > 0;
          } else {
            this.showData = false;
            console.log("failed resp:", response);
          }
        });
      } catch (error) {
        console.log("errrr", error);
        this.showData = false;
      } finally {
        this.hideLoading();
      }
    },
    async getEstimations_owas(event, selectedModality) {
      console.log("event", event);
      console.log("selectedModality:", selectedModality);
      this.selectedDate = event;
      this.selectedYear = event.year();
      this.selectedMonth = event.month() + 1;
      let payload = {
        session_id: store.getters.StateSessionId,
        year: event.year(),
        month: event.month() + 1,
        modality: selectedModality,
        product: "owas",
      };
      console.log(payload);
      try {
        this.showLoading();

        await this.GetEstimations_owas(payload).then((response) => {
          if (response && !response["warning"]) {
            console.log("selected", response.emotion_timeseries);
            if (this.selectedModality == "nlp") {
              let nlp_series = [];
              for (let key in response["emotion"]) {
                nlp_series.push({
                  name: key,
                  data: [0, response["emotion"][key]],
                });
              }

              for (let key in response["sensitive_info"]) {
                nlp_series.push({
                  name: key,
                  data: [0, 0, response["sensitive_info"][key]],
                });
              }

              for (let key in response["text"]) {
                nlp_series.push({
                  name: key,
                  data: [response["text"][key]],
                });
              }
              this.nlp_series = nlp_series;
              console.log(this.nlp_series);
            }
            if (this.selectedModality == "image") {
              let image_series = [];
              for (let key in response["nudity_result"]) {
                image_series.push({
                  name: key,
                  data: [response["nudity_result"][key]],
                });
              }
              this.image_series = image_series;
            }

            if (this.selectedModality == "csam") {
              let csam_series = [];
              for (let key in response["csam_result"]) {
                csam_series.push({
                  name: key,
                  data: [response["csam_series"][key]],
                });
              }
              this.csam_series = csam_series;
            }

            this.allTransactionCount = response["all_transaction_count"];

            this.showData_owas = true;
          } else {
            this.showData_owas = false;

            this.allTransactionCount = 0;
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.hideLoading();
      }
    },
  },
  data() {
    return {
      dateMP: moment().format("MMMM YYYY"),
      dateMP_owas: moment().format("MMMM YYYY"),

      menuMP: "",
      menuMP_owas: "",

      selectedDate: moment(),
      selectedDate_owas: moment(),

      dateMP2: "",
      dateMP2_owas: "",

      owas_titles: {
        nlp: "NLP",
        image: "Image",
        csam: "CSAM",
      },

      visible: false,
      showData: false,
      showData_owas: false,

      selectedYear: 0,
      selectedMonth: 0,
      loading: false,
      selectedModality: "image",
      tab: null,
      owas_tab: null,
      allTransactionCount: 0,
      monthFormat: "LLL",
      options: {},
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#7AC142",
              "#007CC3",
              "#00529B",
              "#F47A1F",
              "#FDBB2F",
              "#377B2B",
            ],
            pointStyle: "rect",
            data: [0, 0],
          },
        ],
      },
      nlp_timeseries_series: [
        {
          name: "anger",
          data: [2, 0, 0],
        },
        {
          name: "neutral",
          data: [1, 0, 0],
        },
        {
          name: "address",
          data: [1, 0, 0],
        },
        {
          name: "profanity",
          data: [1, 0, 0],
        },
        {
          name: "hate_speech",
          data: [1, 0, 0],
        },
        {
          name: "text_analyzed",
          data: [3, 0, 0],
        },
        {
          name: "toxic_speech",
          data: [2, 0, 0],
        },
      ],
      nlp_timeseries_chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          stacked: "true",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Timeseries",
          align: "left",
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              ""
            );
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: ["28 Nov", "29 Nov", "30 Nov"],
        },
        tooltip: {
          y: [
            {},
            {},
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      lineChartData: {
        labels: [],
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        datasets: [
          {
            data: [],
            label: "Estimation Count",
            pointStyle: "rect",
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      },
      metricData: {
        estimationsThisMonth: {
          metricTitle: "Estimations this month",
          metricValue: "3045",
          metricPercentage: 0,
          hasRisen: "minus",
        },
        averageEstimationsPerDay: {
          metricTitle: "Average estimations per day",
          metricValue: "179.12",
          metricPercentage: 0,
          hasRisen: "minus",
        },
        totalSpoofDetections: {
          metricTitle: "Total Spoof Detections",
          metricValue: "1197",
          metricPercentage: 0,
          hasRisen: "minus",
        },
        latencyStats: "",
      },
      image_series: [],
      nlp_series: [],
      csam_series: [],
      image_chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {},
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "category",
          categories: ["nudity_result"],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
      nlp_chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {},
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "category",
          categories: ["text_analysis", "emotion_x", "sensitive_info"],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
      csam_chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {},
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "category",
          categories: ["csam_result"],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
};
</script>