<template>
  <v-app>
    <v-btn
      :to="online_safety_route"
      block
      color="green"
      dark
      class="rounded-0 hidden-lg-and-up"
      elevation="0"
      :ripple="false"
      style="text-transform: unset !important"
      >Go to Online Safety&nbsp;&nbsp;&nbsp;<v-icon
        >mdi-arrow-right-circle</v-icon
      >
    </v-btn>
    <v-navigation-drawer v-if="!infoDrawer" app :width="300" floating>
      <v-toolbar elevation="0" color="grey lighten-4" lighten>
        <span style="text-align: left" class="title ml-3 mr-5">
          Privately&nbsp;
          <span class="font-weight-thin subtitle-1"> &nbsp; Developer Hub</span>
        </span>

        <a href="https://privately.eu" target="blank">
          <v-btn fab icon>
            <v-avatar size="45">
              <img :src="require('@/assets/images/logo.png')" alt="logo" />
            </v-avatar>
          </v-btn> </a
      ></v-toolbar>

      <v-expansion-panels flat accordion v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-medium text-h6">
            FaceAssure SDKs
            <template v-slot:actions>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels flat accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Android
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  ><ul style="list-style-type: none" outlined>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          href="/static/android-sdk/getting_started.html"
                          >Getting Started</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          href="https://github.com/privately-corp/samples/tree/main/AgeAssure/Android"
                          style="text-decoration: none"
                        >
                          Samples</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          href="/static/android-sdk/index.html"
                          >Documentation</a
                        ></span
                      >
                    </li>
                  </ul>
                </v-expansion-panel-content>
                <v-divider></v-divider>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  iOS
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  ><ul style="list-style-type: none" outlined>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          href="/static/ios-sdk/getting_started.html"
                          >Getting Started</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          href="https://github.com/privately-corp/samples/tree/main/AgeAssure/iOS"
                          style="text-decoration: none"
                        >
                          Samples</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          href="/static/ios-sdk/index.html"
                          >Documentation</a
                        ></span
                      >
                    </li>
                  </ul>
                </v-expansion-panel-content>
                <v-divider></v-divider>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="subtitle-1">
                  Browser
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul style="list-style-type: none" outlined>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          href="/static/web/getting_started.html"
                          >Getting Started</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          href="https://github.com/privately-corp/samples/tree/main/AgeAssure/Web"
                          style="text-decoration: none"
                        >
                          Samples</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1">Documentation</span>
                    </li>
                  </ul></v-expansion-panel-content
                >
                <v-divider></v-divider>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels flat accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-medium text-h6"
            >VoiceAssure SDKs</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <ul style="list-style-type: none" outlined>
              <li style="margin: 10px 0">
                <span class="subtitle-1"
                  ><a
                    target="_blank"
                    style="text-decoration: none"
                    href="/static/web/voiceassure/getting_started.html"
                    >Getting Started</a
                  ></span
                >
              </li>
            </ul></v-expansion-panel-content
          >
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn
        elevation="0"
        style="top: 60%; left: -15px; padding-right: 1px"
        fixed
        color="#18739dCC"
        left
        dark
        small
        @click.stop="
          $vuetify.goTo(0);
          infoDrawer = !infoDrawer;
        "
      >
        <v-icon> mdi-information-variant </v-icon>
      </v-btn>

      <template v-slot:append>
        <v-btn
          :to="online_safety_route"
          block
          color="green"
          dark
          class="rounded-0 mb-8"
          elevation="0"
          :ripple="false"
          style="text-transform: unset !important"
          >Go to Online Safety&nbsp;&nbsp;&nbsp;<v-icon
            >mdi-arrow-right-circle</v-icon
          >
        </v-btn>
        <v-divider></v-divider>
        <v-list-item
          link
          :ripple="false"
          href="/static/docs/privacy_policy.pdf"
          target="_blank"
        >
          <v-list-item-content style="text-align: center">
            <v-list-item-title>Privacy Policy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :ripple="false"
          href="/static/docs/terms_and_conditions.pdf"
          target="_blank"
        >
          <v-list-item-content style="text-align: center">
            <v-list-item-title>Terms and Conditions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link href="mailto:contact@privately.eu" :ripple="false">
          <v-list-item-content style="text-align: center">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-btn
          style="text-transform: unset !important"
          block
          :to="signup_route"
          class="rounded-0"
          x-large
          elevation="0"
          color="#18739d"
          dark
        >
          <span>Free Trial</span>
        </v-btn>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      elevation="0"
      :width="500"
      v-if="infoDrawer"
      v-model="infoDrawer"
      color="#18739d"
      absolute
      temporary
      :height="mobileView ? 500 : 400"
      :style="mobileView ? 'top: 5%' : 'top: 10%'"
      ><v-container fluid>
        <span class="white--text text-h5 font-weight-medium"
          >Welcome to Privately Developer Hub!</span
        >
        <br />
        <br />

        <span class="subtitle-2 white--text"
          ><p class="font-weight-thin">
            If you are developing an app or a game that is likely to be accessed
            by children you might have to build age appropriate versions of your
            app or game. In the UK the
            <a
              target="_blank"
              style="color: #6fdcff; text-decoration: none; font-weight: bold"
              href="https://ico.org.uk/your-data-matters/the-children-s-code-what-is-it/"
            >
              Children’s Code</a
            >
            has already become law - and similar legislations are on across the
            <a
              target="_blank"
              style="color: #6fdcff; text-decoration: none; font-weight: bold"
              href="https://digital-strategy.ec.europa.eu/en/policies/digital-services-act-package"
              >EU</a
            >
            and
            <a
              target="_blank"
              style="color: #6fdcff; text-decoration: none; font-weight: bold"
              href="https://californiaaadc.com/"
              >California</a
            >
            . At the heart of this legislation is age verification. This page is
            aimed at providing developers access to Privately’s ‘on-device’ and
            ‘on-browser’ age verification and online safety technologies. Our
            technology helps you to identify the age range of your users without
            collecting any biometrics or any other personally identifiable data
            for this process. Our online safety technologies help you keep young
            people safe.
          </p>
        </span>

        <v-btn
          @click="dismiss"
          style="text-transform: unset !important"
          absolute
          bottom
          right
          >Dismiss</v-btn
        >
      </v-container>
    </v-navigation-drawer>

    <v-container fluid>
      <v-btn
        v-if="false"
        class="hidden-md-and-up"
        :to="signup_route"
        style="text-transform: unset !important"
        outlined
        absolute
        top
        right
        dark
        small
      >
        Contact Us
      </v-btn>
      <v-row style="background: #18739d">
        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
          <div
            class="text-h3 font-weight-bold"
            style="padding: 20px; margin-top: 15px; color: white"
          >
            Solutions For Developers
          </div>
        </v-col>

        <v-col xs="12" sm="12" md="8" lg="8" xl="8" style="text-align: left">
          <v-row align="center" justify="start">
            <v-col cols="12">
              <div
                class="text-h6 font-weight-light"
                style="
                  padding: 20px;
                  margin-top: 15px;
                  height: 35px;
                  color: white;
                "
              >
                Demo Apps Available
              </div></v-col
            >
          </v-row>
          <v-row align="center">
            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <a
                href="https://play.google.com/store/apps/details?id=ch.privately.margin.demo"
                target="_blank"
                ><img :src="require('@/assets/images/android.png')" height="60"
              /></a>
            </v-col>

            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <a
                href="https://apps.apple.com/us/app/multimodal-age-estimation/id1600464793"
                target="_blank"
              >
                <img
                  :src="require('@/assets/images/appstore.svg')"
                  height="44px"
              /></a>
            </v-col>
            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <a
                href="https://showroom-demo.privately.swiss/video_age_verification?session_id=67ed4d7a-41c6-4a46-aafb-b58ccc2c29e4&session_password=6iiYEnPDt.mqE-FIAh6HPIsoA0vB2_"
                target="_blank"
                ><img :src="require('@/assets/images/web.png')" height="50"
              /></a>
            </v-col>

            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <a
                href="https://showroom.privately.swiss/audio_recording"
                target="_blank"
                ><img
                  :src="require('@/assets/images/voice_demo.png')"
                  height="50"
              /></a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn
        style="text-transform: unset !important"
        outlined
        absolute
        top
        right
        dark
        small
        :class="mobileView ? 'mt-8' : ''"
        :to="login_route"
      >
        Login
        <v-icon> mdi-format-horizontal-align-right </v-icon>
      </v-btn>
    </v-container>

    <v-container class="py-8" fluid>
      <v-row>
        <v-col
          class="text-h4"
          style="margin: 10px; color: #18739d; font-size: 29px !important"
        >
          On-Device and On-Browser (Privacy Preserving) Age Awareness technology
          for your apps
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          lg="3"
          xl="3"
          md="2"
          xs="12"
          sm="12"
          class="subtitle-1"
          style="margin: 10px 0px 50px 0; color: #18739d"
          ><span>
            Our solutions can be deployed natively on Android
            <v-icon color="#a4c639" medium>mdi-android</v-icon> and iOS
            <v-icon medium color="#A2AAAD">mdi-apple</v-icon>, as well as on any
            recent browser.</span
          >
          <v-divider
            style="border-style: dashed; border-color: #cecece"
            class="mt-8"
          />
          <v-row class="mt-8" justify="center" align="center"
            ><v-col>
              <a
                href="https://www.accscheme.com/registry/privately-sa"
                target="_blank"
                ><v-img
                  class="ml-auto mr-auto"
                  width="275px"
                  :src="require('@/assets/images/accs_cert.png')"
                ></v-img></a></v-col
          ></v-row>
        </v-col>
        <v-col
          lg="8"
          xl="8"
          md="8"
          sm="12"
          xs="12"
          style="padding: 10px; color: #18739d; height: 350px !important"
        >
          <carousel
            :perPageCustom="[
              [768, 3],
              [1024, 3],
            ]"
            :autoplay="true"
          >
            <slide
              ><v-hover v-slot="{ hover }"
                ><v-card elevation="0" class="mx-auto" max-width="120">
                  <v-row align="center" justify="center">
                    <v-col
                      ><v-expand-transition>
                        <v-btn
                          block
                          v-if="hover"
                          max-width="120"
                          elevation="0"
                          link
                          href="https://play.google.com/store/apps/details?id=ch.privately.margin.demo"
                          target="_blank"
                          dark
                          text
                          class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal subtitle-2 rounded-0"
                          style="
                            background-color: #18739d;
                            text-transform: unset !important;
                          "
                        >
                          Try Now!
                        </v-btn> </v-expand-transition
                      ><v-img
                        :src="require('@/assets/images/carousel-2.png')"
                        contain
                    /></v-col>
                  </v-row>

                  <v-card-title class="subtitle-1 justify-center">
                    Android
                  </v-card-title>
                </v-card></v-hover
              >
            </slide>

            <slide>
              <v-hover v-slot="{ hover }">
                <v-card elevation="0" class="mx-auto" max-width="110">
                  <v-row align="center" justify="center">
                    <v-col
                      ><v-expand-transition>
                        <v-btn
                          max-width="110"
                          block
                          v-if="hover"
                          elevation="0"
                          text
                          :ripple="false"
                          href="https://apps.apple.com/us/app/multimodal-age-estimation/id1600464793"
                          target="_blank"
                          dark
                          class="d-flex transition-fast-in-fast-out darken-2 subtitle-2 v-card--reveal rounded-0"
                          style="
                            background-color: #18739d;
                            text-transform: unset !important;
                          "
                        >
                          Try Now!
                        </v-btn> </v-expand-transition
                      ><v-img
                        :src="require('@/assets/images/carousel-1.png')"
                        contain
                      />
                    </v-col>
                  </v-row>

                  <v-card-title class="subtitle-1 justify-center">
                    iOS
                  </v-card-title>
                </v-card></v-hover
              >
            </slide>

            <slide
              ><v-hover v-slot="{ hover }"
                ><v-card elevation="0" class="mx-auto" max-width="225">
                  <v-row align="center" justify="center">
                    <v-col
                      ><v-expand-transition>
                        <v-btn
                          block
                          v-if="hover"
                          elevation="0"
                          link
                          text
                          dark
                          target="_blank"
                          href="https://showroom-demo.privately.swiss/video_age_verification?session_id=67ed4d7a-41c6-4a46-aafb-b58ccc2c29e4&session_password=6iiYEnPDt.mqE-FIAh6HPIsoA0vB2_"
                          class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal subtitle-2 rounded-0"
                          style="
                            background-color: #18739d;
                            text-transform: unset !important;
                          "
                        >
                          Try Now!
                        </v-btn> </v-expand-transition
                      ><v-img
                        :src="require('@/assets/images/carousel-3.png')"
                        contain
                    /></v-col>
                  </v-row>

                  <v-card-title class="subtitle-1 justify-center">
                    Browser
                  </v-card-title>
                </v-card></v-hover
              >
            </slide>
          </carousel>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-container fluid class="py-10">
      <v-row justify="center">
        <v-col cols="12" md="5"
          ><v-card
            :height="cardHeight"
            class="rounded-0"
            dark
            elevation="0"
            color="#18739d"
          >
            <v-card-title
              ><span
                class="text-h5 font-weight-bold"
                style="word-break: break-word"
                >Native Age Estimation SDK - Android, iOS
              </span></v-card-title
            >

            <v-card-subtitle class="py-5">
              Privately's Age Estimation SDKs allows you to add age awareness to
              any iOS or Android application within minutes. Our privacy
              preserving technology lets you learn about your users without ever
              endangering their data - no personal data ever leaves the device.
            </v-card-subtitle></v-card
          ></v-col
        >
        <v-col cols="12" md="5"
          ><v-card
            :height="cardHeight"
            class="rounded-0"
            dark
            elevation="0"
            color="#18739d"
          >
            <v-card-title
              ><span
                class="text-h5 font-weight-bold"
                style="word-break: break-word"
                >Age Estimation SDK for the Web
              </span></v-card-title
            >

            <v-card-subtitle class="py-5">
              Our Web based solution lets you add age estimation to your
              website, from improving the checkout process of e-commerce
              services to protecting young users online, all the while
              preserving user's data and privacy.
            </v-card-subtitle></v-card
          ></v-col
        >
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row justify="center">
        <v-col xl="5" lg="5" md="5" sm="12" xs="12">
          <v-card class="rounded-0" dark elevation="0" color="#18739d">
            <v-card-subtitle
              class="py-5 white--text font-italic font-weight-bold"
            >
              Good Practice: The Zero Data Principle, which appears to
              fundamentally underpin Privately’s approach to development and
              commercialisation, is an excellent approach which helps to ensure
              that Privately’s final product is as privacy conscious as
              possible, and seriously reduces the risk of a data subject’s
              rights being breached, or any aspect of data protection
              legislation being infringed by the operation of this service.

              <a
                href="https://ico.org.uk/action-weve-taken/audits-and-overview-reports/privately/"
                target="_blank"
                ><v-img
                  style="margin-top: 10px"
                  :src="require('@/assets/images/ico-logo.png')"
                  contain
                  height="90"
              /></a> </v-card-subtitle
          ></v-card>
        </v-col>
      </v-row>
    </v-container>
    <br />
    <v-container fluid>
      <v-row align="center" style="background: #18739d">
        <v-col style="text-align: center; margin-top: 10px" cols="12"
          ><span class="text-h4 font-weight-bold white--text"
            >Start Your Free Trial Now</span
          >
          <br /><br />
          <span class="text-h7 font-weight-light white--text"
            >Start building! Sign up a free trial and test out our
            solutions.</span
          >
          <p></p>
          <v-row justify="center" style="margin-bottom: 10px">
            <v-col md="4">
              <v-btn
                :to="signup_route"
                class="rounded-0"
                x-large
                elevation="0"
                color="#6fdcff"
                dark
              >
                <span style="color: black">NATIVE SDK &amp; PLUGINS</span>
              </v-btn>
            </v-col>
            <v-col md="4">
              <v-btn
                elevation="0"
                :to="signup_route"
                class="rounded-0"
                x-large
                color="#6fdcff"
                dark
              >
                <span style="color: black">SDK FOR THE WEB</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-10">
      <v-row justify="center">
        <v-col md="4">
          <v-img :src="require('@/assets/images/home-margin.png')" contain
        /></v-col>

        <v-col md="4"
          ><v-card elevation="0" color="transparent">
            <v-card-title>Accurate and Fast</v-card-title>
            <v-card-subtitle>
              Our technology can make highly accurate age estimations based on
              analysis of facial and voice based features . Through this SDK 4
              age brackets are available: (0-13, 13-17, 18-24,
              25+)</v-card-subtitle
            ><v-btn
              href="/static/docs/SDK_Benchmark.pdf"
              target="_blank"
              class="ma-4 rounded-0"
              outlined
              color="indigo"
            >
              LEARN MORE ABOUT PERFORMANCE
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="py-8">
        <v-col md="4"
          ><v-card elevation="0" color="transparent">
            <v-card-title>Operational Requirements</v-card-title>
            <v-card-subtitle>
              Our system's age estimation is the most reliable under a set of
              operational requirements. Follow these instructions, and you'll
              get the best out of it.</v-card-subtitle
            ><v-btn
              class="rounded-0 ma-4"
              href="/static/docs/SDK_Operational-Requirements.pdf"
              target="_blank"
              outlined
              color="indigo"
            >
              LEARN MORE
            </v-btn>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-img
            :src="require('@/assets/images/online-safety-intro.jpeg')"
            contain
        /></v-col>
      </v-row>
      <v-row justify="center" class="hidden-lg-and-up"
        ><v-col cols="12" style="text-align: center"
          ><v-list class="justify=center">
            <template v-for="(item, index) in items">
              <div
                style="padding: 12px"
                v-if="item.header"
                :key="index"
                v-html="item.header"
              ></div>

              <v-divider
                class="justify-center"
                style="border-top: dotted 1px; color: #cecece"
                v-else-if="item.divider"
                :key="index"
              ></v-divider>

              <ul style="list-style-type: none" v-else outlined :key="index">
                <li style="margin: 10px 0">
                  <span class="subtitle-1" v-html="item.title"></span>
                </li>
              </ul>
            </template> </v-list
        ></v-col>
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import FooterItem from "@/components/FooterItem.vue";

export default {
  components: {
    Carousel,
    Slide,
    FooterItem,
  },
  computed: {
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return 240;
        case "xl":
          return 180;
      }
      return true;
    },
    mobileView() {
      return (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },
  },
  methods: {
    dismiss() {
      this.infoDrawer = false;
    },
  },
  mounted() {
    if (!localStorage.getItem("infoDismissed")) {
      setTimeout(() => {
        this.infoDrawer = true;
        localStorage.setItem("infoDismissed", true);
      }, 1000);
    }
  },

  data: () => ({
    panel: 0,
    windowHeight: window.innerHeight,
    infoDrawer: false,
    links: [
      {
        title: "Privacy Policy",
        link: "/static/docs/privacy_policy.pdf",
      },
      {
        title: "Terms and Conditions",
        link: "/static/docs/terms_and_conditions.pdf",
      },
      {
        title: "Contact Us",
        link: "mailto:contact@privately.eu",
        mailto: true,
      },
    ],
    signup_route: "/signup",
    login_route: "/login",
    online_safety_route: "/home/online-safety",
    infoDismissed: false,
    items: [
      {
        header:
          '<span class="font-weight-bold">AGE ESTIMATION SDK - <span class="subtitle-1">BROWSER</span></span>',
      },
      { divider: true },
      {
        title:
          "<a target='_blank' style='text-decoration:none' href='/static/web/getting_started.html'>Getting Started</a>",
      },
      {
        title:
          "<a target='_blank' href='https://github.com/privately-corp/samples/tree/main/AgeAssure/Web' style='text-decoration:none'> Samples</a>",
      },

      {
        title: "Documentation",
      },

      {
        header:
          '<span class="font-weight-bold ">AGE ESTIMATION SDK - <span class="subtitle-1">ANDROID</span></span>',
        icon: "mdi-android",
      },
      { divider: true },
      {
        title:
          "<a target='_blank' style='text-decoration:none' href='/static/android-sdk/getting_started.html'>Getting Started</a>",
      },
      {
        title:
          "<a target='_blank' href='https://github.com/privately-corp/samples/tree/main/AgeAssure/Android' style='text-decoration:none'> Samples</a>",
      },

      {
        title:
          "<a target='_blank' style='text-decoration:none' href='/static/android-sdk/index.html'>Documentation</a>",
      },
      {
        header:
          '<span class="font-weight-bold">AGE ESTIMATION SDK - <span class="subtitle-1">IOS</span></span>',
      },
      { divider: true },
      {
        title:
          "<a target='_blank' style='text-decoration:none' href='/static/ios-sdk/getting_started.html'>Getting Started</a>",
      },
      {
        title:
          "<a target='_blank' href='https://github.com/privately-corp/samples/tree/main/AgeAssure/iOS' style='text-decoration:none'> Samples</a>",
      },

      {
        title:
          "<a target='_blank' style='text-decoration:none' href='/static/ios-sdk/index.html'>Documentation</a>",
      },
    ],
  }),
};
</script>

