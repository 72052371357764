<template>
  <v-app>
    <v-btn
      :to="age_route"
      block
      color="green"
      dark
      class="rounded-0 hidden-lg-and-up"
      elevation="0"
      :ripple="false"
      style="text-transform: unset !important"
      >Go to Age Assurance&nbsp;&nbsp;&nbsp;<v-icon
        >mdi-arrow-right-circle</v-icon
      >
    </v-btn>
    <v-navigation-drawer v-if="!infoDrawer" app :width="300" floating>
      <v-toolbar elevation="0" color="grey lighten-4" lighten>
        <span style="text-align: left" class="title ml-3 mr-5">
          Privately&nbsp;
          <span class="font-weight-thin subtitle-1"> &nbsp; Developer Hub</span>
        </span>

        <a href="https://privately.eu" target="blank">
          <v-btn fab icon>
            <v-avatar size="45">
              <img :src="require('@/assets/images/logo.png')" alt="logo" />
            </v-avatar>
          </v-btn> </a
      ></v-toolbar>

      <v-expansion-panels flat accordion v-model="panel">
        <v-expansion-panel v-for="(item, index) in docs_items" :key="index">
          <v-expansion-panel-header class="font-weight-medium text-h6">
            {{ item.title }}
            <template v-slot:actions>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels flat accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Android
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  ><ul style="list-style-type: none" outlined>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          :href="item.android.gettingStarted"
                          >Getting Started</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          :href="item.android.samples"
                          style="text-decoration: none"
                        >
                          Samples</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          style="text-decoration: none"
                          :href="item.android.documentation"
                          >Documentation</a
                        ></span
                      >
                    </li>
                  </ul>
                </v-expansion-panel-content>
                <v-divider></v-divider>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  iOS
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  ><ul style="list-style-type: none" outlined>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          :href="item.ios.gettingStarted"
                          style="text-decoration: none"
                          >Getting Started</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          :href="item.ios.samples"
                          style="text-decoration: none"
                        >
                          Samples</a
                        ></span
                      >
                    </li>
                    <li style="margin: 10px 0">
                      <span class="subtitle-1"
                        ><a
                          target="_blank"
                          :href="item.ios.documentation"
                          style="text-decoration: none"
                          >Documentation</a
                        ></span
                      >
                    </li>
                  </ul>
                </v-expansion-panel-content>
                <v-divider></v-divider>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn
        elevation="0"
        style="top: 60%; left: -15px; padding-right: 1px"
        fixed
        color="#18739dCC"
        left
        dark
        small
        @click.stop="
          $vuetify.goTo(0);
          infoDrawer = !infoDrawer;
        "
      >
        <v-icon> mdi-information-variant </v-icon>
      </v-btn>

      <template v-slot:append>
        <v-btn
          :to="age_route"
          block
          color="green"
          dark
          class="rounded-0 mb-8"
          elevation="0"
          :ripple="false"
          style="text-transform: unset !important"
          >Go to Age Assurance&nbsp;&nbsp;&nbsp;<v-icon
            >mdi-arrow-right-circle</v-icon
          >
        </v-btn>
        <v-divider></v-divider>
        <v-list-item
          link
          :ripple="false"
          href="/static/docs/privacy_policy.pdf"
          target="_blank"
        >
          <v-list-item-content style="text-align: center">
            <v-list-item-title>Privacy Policy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :ripple="false"
          href="/static/docs/terms_and_conditions.pdf"
          target="_blank"
        >
          <v-list-item-content style="text-align: center">
            <v-list-item-title>Terms and Conditions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link href="mailto:contact@privately.eu" :ripple="false">
          <v-list-item-content style="text-align: center">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-btn
          style="text-transform: unset !important"
          block
          :to="signup_route"
          class="rounded-0"
          x-large
          elevation="0"
          color="#18739d"
          dark
        >
          <span>Free Trial</span>
        </v-btn>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      elevation="0"
      :width="500"
      v-if="infoDrawer"
      v-model="infoDrawer"
      color="#18739d"
      absolute
      temporary
      height="400"
      style="top: 10%"
      ><v-container fluid>
        <span class="white--text text-h5 font-weight-medium"
          >Welcome to Privately Developer Hub!</span
        >
        <br />
        <br />

        <span class="subtitle-2 white--text"
          ><p class="font-weight-thin">
            If you are developing an app or a game that is likely to be accessed
            by children you might have to build age appropriate versions of your
            app or game. In the UK the
            <a
              target="_blank"
              style="color: #6fdcff; text-decoration: none; font-weight: bold"
              href="https://ico.org.uk/your-data-matters/the-children-s-code-what-is-it/"
            >
              Children’s Code</a
            >
            has already become law - and similar legislations are on across the
            <a
              target="_blank"
              style="color: #6fdcff; text-decoration: none; font-weight: bold"
              href="https://digital-strategy.ec.europa.eu/en/policies/digital-services-act-package"
              >EU</a
            >
            and
            <a
              target="_blank"
              style="color: #6fdcff; text-decoration: none; font-weight: bold"
              href="https://californiaaadc.com/"
              >California</a
            >
            . At the heart of this legislation is age verification. This page is
            aimed at providing developers access to Privately’s ‘on-device’ and
            ‘on-browser’ age verification and online safety technologies. Our
            technology helps you to identify the age range of your users without
            collecting any biometrics or any other personally identifiable data
            for this process. Our online safety technologies help you keep young
            people safe.
          </p>
        </span>

        <v-btn
          @click="dismiss"
          style="text-transform: unset !important"
          absolute
          bottom
          right
          >Dismiss</v-btn
        >
      </v-container>
    </v-navigation-drawer>

    <v-container fluid>
      <v-btn
        v-if="false"
        class="hidden-md-and-up"
        :to="signup_route"
        style="text-transform: unset !important"
        outlined
        absolute
        top
        right
        dark
        small
      >
        Contact Us
      </v-btn>
      <v-row style="background: #18739d">
        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
          <div
            class="text-h3 font-weight-bold"
            style="padding: 20px; margin-top: 15px; color: white"
          >
            Solutions For Developers
          </div>
        </v-col>

        <v-col xs="12" sm="12" md="8" lg="8" xl="8" style="text-align: left">
          <v-row align="center" justify="start">
            <v-col cols="12">
              <div
                class="text-h6 font-weight-light"
                style="
                  padding: 20px;
                  margin-top: 15px;
                  height: 35px;
                  color: white;
                "
              >
                Demo Apps Available
              </div></v-col
            >
          </v-row>
          <v-row align="center">
            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <img :src="require('@/assets/images/android.png')" height="60" />
            </v-col>

            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <img
                :src="require('@/assets/images/appstore.svg')"
                height="44px"
              />
            </v-col>
            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
              ><img :src="require('@/assets/images/web.png')" height="50" />
            </v-col>

            <v-col
              xl="2"
              lg="3"
              md="4"
              xs="12"
              sm="6"
              style="text-align: center"
            >
              <img
                :src="require('@/assets/images/voice_demo.png')"
                height="50"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn
        style="text-transform: unset !important"
        outlined
        absolute
        top
        right
        dark
        small
        :to="login_route"
        :class="mobileView ? 'mt-8' : ''"
      >
        Login
        <v-icon> mdi-format-horizontal-align-right </v-icon>
      </v-btn>
    </v-container>

    <v-container class="py-8" fluid>
      <v-row>
        <v-col
          class="text-h4"
          style="margin: 10px; color: #18739d; font-size: 29px !important"
        >
          On-Device Privacy Preserving Online Safety solutions for text and
          images
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          lg="3"
          xl="3"
          md="2"
          xs="12"
          sm="12"
          class="subtitle-1"
          style="margin: 10px 0px 50px 0; color: #18739d"
          ><span>
            Our solutions can be deployed natively on Android
            <v-icon color="#a4c639" medium>mdi-android</v-icon> and iOS
            <v-icon medium color="#A2AAAD">mdi-apple</v-icon></span
          >
        </v-col>
        <v-col
          lg="8"
          xl="8"
          md="8"
          sm="12"
          xs="12"
          style="padding: 10px; color: #18739d; height: 350px !important"
        >
          <carousel
            :perPageCustom="[
              [768, 3],
              [1024, 3],
            ]"
          >
            <slide
              ><v-card elevation="0" class="mx-auto" max-width="110">
                <v-row align="center" justify="center">
                  <v-col
                    ><v-img
                      :src="require('@/assets/images/carousel-owas-1.png')"
                      contain
                  /></v-col>
                </v-row>

                <v-card-title class="subtitle-1 justify-center">
                  Android
                </v-card-title>
              </v-card>
            </slide>

            <slide>
              <v-card elevation="0" class="mx-auto" max-width="110">
                <v-row align="center" justify="center">
                  <v-col
                    ><v-img
                      :src="require('@/assets/images/carousel-owas-2.png')"
                      contain
                    />
                  </v-col>
                </v-row>

                <v-card-title class="subtitle-1 justify-center">
                  Android
                </v-card-title>
              </v-card>
            </slide>
            <slide>
              <v-card elevation="0" class="mx-auto" max-width="110">
                <v-row align="center" justify="center">
                  <v-col
                    ><v-img
                      :src="require('@/assets/images/carousel-owas-3.png')"
                      contain
                    />
                  </v-col>
                </v-row>

                <v-card-title class="subtitle-1 justify-center">
                  iOS
                </v-card-title>
              </v-card>
            </slide>
          </carousel>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-container fluid class="py-10">
      <v-row justify="center">
        <v-col cols="12" md="5"
          ><v-card
            :height="cardHeight"
            class="rounded-0"
            dark
            elevation="0"
            color="#18739d"
          >
            <v-card-title
              ><span
                class="text-h5 font-weight-bold"
                style="word-break: break-word"
                >Native Online Safety SDK for text - Android, iOS
              </span></v-card-title
            >

            <v-card-subtitle class="py-5">
              Privately's <b>Online Safety And Wellbeing</b> (OWAS) SDKs allow
              you to make your platform safer for all users. Our privacy
              preserving technology lets you detect <b>hate speech</b>,
              <b>toxicity</b> and a <b>wide range of emotions</b> without ever
              endangering your users' privacy - no personal data ever leaves the
              device.
            </v-card-subtitle></v-card
          ></v-col
        >
        <v-col cols="12" md="5"
          ><v-card
            :height="cardHeight"
            class="rounded-0"
            dark
            elevation="0"
            color="#18739d"
          >
            <v-card-title
              ><span
                class="text-h5 font-weight-bold"
                style="word-break: break-word"
                >Native Online Safety SDK for image - Android, iOS
              </span></v-card-title
            >

            <v-card-subtitle class="py-5">
              Our online safety SDKs for image lets you detect when nudity is
              present in any image. Whenever users are taking a photo or sharing
              a picture, it can be analysed on device without risking leaking
              their personal data. It can also be combined with our FaceAssure
              age detection as a proxy for CSAM detection - all on device.
            </v-card-subtitle></v-card
          ></v-col
        >
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row justify="center">
        <v-col xl="5" lg="5" md="5" sm="12" xs="12">
          <v-card class="rounded-0" dark elevation="0" color="#18739d">
            <v-card-subtitle
              class="py-5 white--text font-italic font-weight-bold"
            >
              Good Practice: The Zero Data Principle, which appears to
              fundamentally underpin Privately’s approach to development and
              commercialisation, is an excellent approach which helps to ensure
              that Privately’s final product is as privacy conscious as
              possible, and seriously reduces the risk of a data subject’s
              rights being breached, or any aspect of data protection
              legislation being infringed by the operation of this service.

              <a
                href="https://ico.org.uk/action-weve-taken/audits-and-overview-reports/privately/"
                target="_blank"
                ><v-img
                  style="margin-top: 10px"
                  :src="require('@/assets/images/ico-logo.png')"
                  contain
                  height="90"
              /></a> </v-card-subtitle
          ></v-card>
        </v-col>
      </v-row>
    </v-container>
    <br />
    <v-container fluid>
      <v-row align="center" style="background: #18739d">
        <v-col style="text-align: center; margin-top: 10px" cols="12"
          ><span class="text-h4 font-weight-bold white--text"
            >Start Your Free Trial Now</span
          >
          <br /><br />
          <span class="text-h7 font-weight-light white--text"
            >Start building! Sign up a free trial and test out our
            solutions.</span
          >
          <p></p>
          <v-row justify="center" style="margin-bottom: 10px">
            <v-col md="4">
              <v-btn
                :to="signup_route"
                class="rounded-0"
                x-large
                elevation="0"
                color="#6fdcff"
                dark
              >
                <span style="color: black">NATIVE NLP SDK</span>
              </v-btn>
            </v-col>
            <v-col md="4">
              <v-btn
                elevation="0"
                :to="signup_route"
                class="rounded-0"
                x-large
                color="#6fdcff"
                dark
              >
                <span style="color: black">NATIVE IMAGE SDK</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-10">
      <v-row justify="center">
        <v-col md="4">
          <v-img :src="require('@/assets/images/home-margin.png')" contain
        /></v-col>

        <v-col md="4"
          ><v-card elevation="0" color="transparent">
            <v-card-title>Accurate and Fast</v-card-title>
            <v-card-subtitle>
              Our technology gives highly accurate results in less than a second
              - even in just a few milliseconds when analysing text. They are
              perfect for real-time processing where response time is
              key.</v-card-subtitle
            ><v-btn
              href="/static/docs/SDK_Benchmark.pdf"
              target="_blank"
              class="ma-4 rounded-0"
              outlined
              color="indigo"
            >
              LEARN MORE ABOUT PERFORMANCE
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="py-8">
        <v-col md="4"
          ><v-card elevation="0" color="transparent">
            <v-card-title>Integration in Minutes</v-card-title>
            <v-card-subtitle>
              Our SDKs are available through package managers and can be
              integrated in a matter of minutes. Add the SDKs as a dependency
              and you're good to go! </v-card-subtitle
            ><v-btn
              class="rounded-0 ma-4"
              href="/static/owas-image-android/getting_started.html"
              target="_blank"
              outlined
              color="indigo"
            >
              LEARN MORE
            </v-btn>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-img
            :src="require('@/assets/images/online-safety-intro.jpeg')"
            contain
        /></v-col>
      </v-row>
      <v-row justify="center" class="hidden-lg-and-up"
        ><v-col cols="12" style="text-align: center"
          ><v-list class="justify=center">
            <template v-for="(item, index) in docs_items">
              <span :key="index" class="font-weight-bold"
                >{{ item.title }} -
                <span class="subtitle-1">ANDROID</span></span
              >
              <v-divider
                class="justify-center"
                style="border-top: dotted 1px; color: #cecece"
                :key="index"
              ></v-divider>
              <ul style="list-style-type: none" outlined :key="index">
                <li style="margin: 10px 0">
                  <a
                    target="_blank"
                    class="subtitle-1"
                    style="text-decoration: none"
                    :href="item.android.gettingStarted"
                    >Getting Started</a
                  >
                </li>
                <li style="margin: 10px 0">
                  <a
                    target="_blank"
                    class="subtitle-1"
                    style="text-decoration: none"
                    :href="item.android.samples"
                    >Samples</a
                  >
                </li>
                <li style="margin: 10px 0">
                  <a
                    target="_blank"
                    class="subtitle-1"
                    style="text-decoration: none"
                    :href="item.android.documentation"
                    >Documentation</a
                  >
                </li>
              </ul>

              <span :key="index" class="font-weight-bold"
                >{{ item.title }} - <span class="subtitle-1">IOS</span></span
              >
              <v-divider
                class="justify-center"
                style="border-top: dotted 1px; color: #cecece"
                :key="index"
              ></v-divider>
              <ul style="list-style-type: none" outlined :key="index">
                <li style="margin: 10px 0">
                  <a
                    target="_blank"
                    class="subtitle-1"
                    style="text-decoration: none"
                    :href="item.ios.gettingStarted"
                    >Getting Started</a
                  >
                </li>
                <li style="margin: 10px 0">
                  <a
                    class="subtitle-1"
                    style="text-decoration: none"
                    target="_blank"
                    :href="item.ios.samples"
                    >Samples</a
                  >
                </li>
                <li style="margin: 10px 0">
                  <a
                    class="subtitle-1"
                    style="text-decoration: none"
                    :href="item.ios.documentation"
                    target="_blank"
                    >Documentation</a
                  >
                </li>
              </ul>
            </template>
          </v-list></v-col
        >
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import FooterItem from "@/components/FooterItem.vue";

export default {
  components: {
    Carousel,
    Slide,
    FooterItem,
  },
  computed: {
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return 270;
        case "xl":
          return 180;
      }
      return true;
    },
    mobileView() {
      return (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },
  },
  methods: {
    dismiss() {
      this.infoDrawer = false;
    },
  },
  mounted() {
    if (!localStorage.getItem("infoDismissed")) {
      setTimeout(() => {
        this.infoDrawer = true;
        localStorage.setItem("infoDismissed", true);
      }, 1000);
    }
  },

  data: () => ({
    panel: 0,
    infoDrawer: false,
    links: [
      {
        title: "Privacy Policy",
        link: "/static/docs/privacy_policy.pdf",
      },
      {
        title: "Terms and Conditions",
        link: "/static/docs/terms_and_conditions.pdf",
      },
      {
        title: "Contact Us",
        link: "mailto:contact@privately.eu",
        mailto: true,
      },
    ],
    docs_items: [
      {
        title: "OWAS Image SDKs",
        android: {
          gettingStarted: "/static/owas-image-android/getting_started.html",
          samples:
            "https://github.com/privately-corp/samples/tree/main/OWAS/Android",
          documentation: "/static/owas-image-android/index.html",
        },
        ios: {
          gettingStarted: "/static/owas-image-ios/getting_started.html",
          samples:
            "https://github.com/privately-corp/samples/tree/main/OWAS/iOS",
          documentation: "/static/owas-image-ios/index.html",
        },
      },
      {
        title: "OWAS NLP SDKs",
        android: {
          gettingStarted: "/static/owas-nlp-android/getting_started.html",
          samples:
            "https://github.com/privately-corp/samples/tree/main/OWAS/Android",
          documentation: "/static/owas-nlp-android/index.html",
        },
        ios: {
          gettingStarted: "/static/owas-nlp-ios/getting_started.html",
          samples:
            "https://github.com/privately-corp/samples/tree/main/OWAS/iOS",
          documentation: "/static/owas-nlp-ios/index.html",
        },
      },
      {
        title: "OWAS CSAM SDKs",
        android: {
          gettingStarted: "/static/owas-csam-android/getting_started.html",
          samples:
            "https://github.com/privately-corp/samples/tree/main/OWAS/Android",
          documentation: "/static/owas-csam-android/index.html",
        },
        ios: {
          gettingStarted: "/static/owas-nlp-ios/getting_started.html",
          samples:
            "https://github.com/privately-corp/samples/tree/main/OWAS/iOS",
          documentation: "/static/owas-nlp-ios/index.html",
        },
      },
    ],
    signup_route: "/signup",
    login_route: "/login",
    age_route: "/home#",
    infoDismissed: false,
  }),
};
</script>
