var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('retail-base-layout',{attrs:{"current":"licenceList"}},[_c('v-app',{staticStyle:{"background":"#fafafa"}},[_c('v-container',[_c('v-card',{staticClass:"card-main",attrs:{"elevation":"0"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"ma-4",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('EstimationCard',{attrs:{"loading":_vm.isLoading,"metric-title":"Total number of licences","metricValue":_vm.getTotalNumberOfLicences}})],1),_c('v-col',[_c('EstimationCard',{attrs:{"loading":_vm.isLoading,"metric-title":"Active","metricValue":_vm.numberOfActivatedLicences}})],1),_c('v-col',[_c('EstimationCard',{attrs:{"loading":_vm.isLoading,"metric-title":"Inactive","metricValue":_vm.numberOfInactiveLicences}})],1)],1)],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.getHeadersDynamically,"items":_vm.flattenedList,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.location,"large":""},on:{"save":function($event){return _vm.updateLocation(item)},"update:returnValue":function($event){return _vm.$set(item, "location", $event)},"update:return-value":function($event){return _vm.$set(item, "location", $event)}},model:{value:(_vm.isEditDialogVisible),callback:function ($$v) {_vm.isEditDialogVisible=$$v},expression:"isEditDialogVisible"}},[_vm._v(" "+_vm._s(item.location)+" "),_c('v-text-field',{attrs:{"slot":"input","rules":[function (v) { return !!v || 'Location cannot be empty'; }],"label":"Location","single-line":""},slot:"input",model:{value:(item.location),callback:function ($$v) {_vm.$set(item, "location", $$v)},expression:"item.location"}})],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-medium"},[_vm._v("List of licences ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticStyle:{"max-width":"450px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","options":_vm.options,"server-items-length":_vm.totalNumberOfLicences},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)],1)]},proxy:true},{key:"item.activated_at_utc",fn:function(ref){
var item = ref.item;
return [(item.deactivated_at_utc == null)?_c('div',[(
                    item.activated_at_utc && item.deactivated_at_utc == null
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{attrs:{"color":!item.activated_at_utc ? 'blue' : 'green',"dark":""}},[_vm._v(" "+_vm._s(item.activated_at_utc ? "Activated" : "Not activated")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("at "+_vm._s(item.activated_at_utc))])]):_c('v-chip',{attrs:{"color":!item.activated_at_utc ? 'blue' : 'green',"dark":""}},[_vm._v(" "+_vm._s(item.activated_at_utc ? "Activated" : "Not activated")+" ")])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Deactivated ")])],1)]}}],null,true)},[_c('span',[_vm._v("at "+_vm._s(item.deactivated_at_utc))])])],1)]}},{key:"item.deeplink_data",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-btn',{staticClass:"reset-text-transform mt-2",attrs:{"color":"black","plain":"","disabled":item.activated_at_utc != false ||
                    item.deactivated_at_utc != null,"text":""},on:{"click":function($event){return _vm.onQRClicked(item)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-qrcode")])],1),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"reset-text-transform mt-2",attrs:{"color":"black","disabled":item.activated_at_utc != false ||
                    item.deactivated_at_utc != null,"plain":"","href":'https://developer.privately.eu/retail-app-activation/' +
                    item.deeplink_data,"target":"_blank","text":""}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-open-in-new")])],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"value":false,"absolute":"","top":"","color":!_vm.errorOccurred ? 'success' : 'red'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"reset-text-transform",attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.dialogForQRCode),callback:function ($$v) {_vm.dialogForQRCode=$$v},expression:"dialogForQRCode"}},[_c('v-card',{staticClass:"justify-center"},[_c('v-card-title',{staticClass:"subtitle-1 grey lighten-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Licence code: ")]),_vm._v(" "+_vm._s(_vm.selectedLicenceCode)+" "),_c('br'),_c('span',{staticClass:"subtitle-1",staticStyle:{"overflow-wrap":"break-word","word-wrap":"break-word","word-break":"break-word"}},[_vm._v("Please scan the QR code below to activate your "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("AgeAI for Retail")]),_vm._v(" licence")])]),_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-card-text',[_c('qrcode-vue',{attrs:{"value":'https://developer.privately.eu/retail-app-activation/' +
                      _vm.selectedDeeplinkData,"size":300,"level":"H"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }