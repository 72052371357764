import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins
import "chartjs-plugin-labels";



export default {
  extends: Pie,
  mixins: [reactiveProp],
  mounted() {
    this.options = {
      plugins: {
        labels: {
          fontColor: "white",
          fontStyle: 'bold',
        },
      },
      responsive: true,
      legend: {
        position: "left",
        labels: {
          usePointStyle: true,
        },
      },
    };
    this.renderChart(this.chartData, this.options)
  }
};
