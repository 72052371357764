<template>
  <v-row justify="center" class="mb-4">
    <v-card
      :height="cardHeight"
      :width="cardWidth"
      elevation="0"
      class="rounded-0 pa-4 mx-auto d-flex flex-column"
      style="border: 1px solid #18739d"
      color="#f5f5f5"
    >
      <v-card-title
        class="subtitle-1"
        style="word-break: break-word"
        :style="smallFont ? 'font-size: 5px !important;' : ''"
        >{{ productName }}&nbsp;
        <div v-if="icons">
          <span>Mobile SDK</span>&nbsp;
          <v-icon color="#18739d">mdi-apple-ios</v-icon>&nbsp;<v-icon
            color="#18739d"
            >mdi-android</v-icon
          >&nbsp;
          <v-icon color="#18739d">mdi-unity</v-icon>
        </div>
      </v-card-title>
      <v-card-subtitle class="subtitle-1">{{ price }}</v-card-subtitle>
      <v-spacer></v-spacer> <v-divider></v-divider><v-spacer></v-spacer>
      <v-list-item two-line v-for="(item, index) in featureList" :key="index">
        <v-icon
          class="mr-2"
          :color="item.unavailable ? 'red' : 'success'"
          small
          >{{ item.unavailable ? "mdi-window-close" : "mdi-check" }}</v-icon
        >
        <v-list-item-content>
          <v-list-item-title style="white-space: normal">
            {{ item.text }}</v-list-item-title
          >
          <v-list-item-subtitle style="white-space: normal">{{
            item.desc
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="justify-center">
        <v-btn
          x-large
          @click="clickFunc"
          :disabled="isDisabled"
          :loading="isLoading"
          width="200px"
          class="text-capitalize rounded-0"
          color="primary"
        >
          <v-icon v-if="planValue != 'sandbox' && planValue != 'deployment'"
            >mdi-email-outline</v-icon
          >&nbsp; {{ buttonName }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
export default {
  components: {},
  props: {
    productName: String,
    price: String,
    icons: Boolean,
    productDesc: String,
    buttonName: String,
    featureList: Array,
    clickFunc: Function,
    isLoading: Boolean,
    planValue: String,
    isSelected: Boolean,
    cancelled_at: Date,
    isDisabled: Boolean,
    cardHeight: String,
    cardWidth: String,
    smallFont: Boolean,
  },
  computed: {},
  data: () => ({}),
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>