<template>
  <v-container>
    <v-card elevation="0">
      <v-row v-if="!loading">
        <v-col>
          <v-row>
            <span class="subtitle-2">
              {{ metricTitle }}
            </span>
          </v-row>

          <v-row>
            <span class="text-h2 font-weight-bold">{{ metricValue }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-skeleton-loader
            max-width="250"
            max-height="77"
            type="card"
          ></v-skeleton-loader> </v-col
      ></v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: [
    "metricTitle",
    "metricValue",
    "metricPercentage",
    "hasRisen",
    "loading",
    "chartAvailable",
    "showModal",
  ],
};
</script>
