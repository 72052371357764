<template>
  <!-- <v-hover v-slot="{ hover }"> -->
  <!-- <template> -->
  <v-card
    width="275"
    style="padding: 15px"
    :color="isActivated ? '#59baeb' : '#ddeedc'"
  >
    <div :class="isActivated ? 'active-text' : ''">
      <span
        ><span style="font-weight: 500">Licence code:</span>
        {{ licenceCode }}</span
      >
      <br />
      <span
        ><span style="font-weight: 500">Status:</span>
        <span :class="!isActivated ? 'not-active' : 'active-text'"
          >{{ isActivated ? " Activated " : " Not activated" }}
        </span></span
      >
    </div>
    <v-dialog v-model="dialog" width="310">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="reset-text-transform mt-2"
          :color="isActivated ? 'white' : 'black'"
          plain
          outlined
          v-bind="attrs"
          v-on="on"
        >
          Show QR Code
          <v-icon>mdi-qrcode</v-icon>
        </v-btn>
      </template>

      <v-card class="justify-center">
        <v-card-title class="text-h5 grey lighten-2">
          {{ licenceCode }}
        </v-card-title>
        <v-container fill-height>
          <v-row justify="center" align="center"
            ><v-col>
              <v-card-text>
                <qrcode-vue
                  :value="
                    'https://developer.privately.eu/retail-app-activation/' +
                    activationToken
                  "
                  :size="size"
                  level="H"
                /> </v-card-text
            ></v-col> </v-row
        ></v-container>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> I accept </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-btn
      class="reset-text-transform mt-4"
      :color="isAcivated ? 'white' : 'black'"
      plain
      outlined
      :disabled="isActivated"
      v-bind="attrs"
      v-on="on"
      :href="
        'https://developer.privately.eu/retail-app-activation/' +
        activationToken
      "
      target="_blank"
    >
      Open via AgeAI app
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-card>

  <!-- </template> -->
  <!-- </v-hover> -->
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  props: ["isActivated", "activationToken", "licenceCode"],
  data() {
    return {
      size: 250,
      dialog: false,
    };
  },
  mounted() {},
  components: {
    QrcodeVue,
  },
  template: '<qrcode-vue :value="value"></qrcode-vue>',
};
</script>
<style scoped>
.active {
  color: #d35d6e;
}

.not-active {
  color: #5aa469;
}

.active-text {
  color: whitesmoke;
}
</style>
