<template>
  <v-app style="background: #fafafa">
    <v-container
      fluid
      fill-height
      class="d-flex align-center justify-center"
      style="min-height: 100vh"
    >
      <v-row
        justify="center"
        align="center"
        class="d-flex flex-column align-center justify-center"
      >
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            elevation="0"
            class="login-card"
            style="width: 100%; max-width: 600px"
          >
            <v-row justify="center" class="py-8">
              <img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
              />
            </v-row>
            <v-alert
              style="text-align: center; max-width: 300px"
              dense
              v-if="isActivated"
              class="login-alert mt-10"
              outlined
              text
              type="success"
            >
              Account activated. Now you can download and login via
              <b>AgeAI for Retail App</b>
            </v-alert>
            <v-alert
              style="text-align: center; max-width: 300px"
              dense
              v-if="showError"
              class="login-alert mt-10"
              outlined
              text
              type="error"
            >
              {{ errorMsg }}
            </v-alert>
            <v-row
              v-if="!showError"
              justify="center"
              align="center"
              style="text-align: center"
            >
              <v-col cols="6">
                <a
                  href="https://play.google.com/store/apps/details?id=ch.privately.ageassure.retail"
                  target="_blank"
                >
                  <v-img
                    contain
                    :src="require('@/assets/images/android.png')"
                    height="200"
                  />
                </a>
              </v-col>
              <v-col cols="6">
                <v-img
                  contain
                  :src="require('@/assets/images/qr-code.png')"
                  height="115"
                />
                <span style="font-size: 15px">
                  Scan the QR code to download the
                  <b> AgeAI for Retail</b> app
                </span>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import jwt from "jsonwebtoken";
import FooterItem from "@/components/FooterItem.vue";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  components: { FooterItem },
  created() {
    if (this.token) {
      try {
        const payload = jwt.verify(this.token, SECRET);
        this.localStorageKey = `emailConfirmed_${payload.email}`;
        if (!localStorage.getItem(this.localStorageKey)) {
          let confirmPayload = {
            email: payload.email,
            password: payload.password,
          };
          this.ConfirmEmail(confirmPayload).then((resp) => {
            console.log(resp);
            if (resp.result == "OK") {
              this.isActivated = true;
              // Set the confirmation flag in local storage
              localStorage.setItem(this.localStorageKey, "true");
            } else {
              this.showError = true;
              this.errorMsg = "Link is invalid or expired";
            }
          });
        } else {
          this.isActivated = true;
        }
      } catch (err) {
        console.log(err);
        this.showError = true;
        this.errorMsg = "Link is invalid or expired";
      }
    }
  },
  methods: {
    ...mapActions(["LogIn", "ConfirmEmail", "GetCurrentPlan"]),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  data() {
    return {
      showIcon: false,
      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
      valid: false,
      token: this.$route.params.token,
      isActivated: false,
      emailErr: "",
      showError: false,
      isLoading: false,
      localStorageKey: "",
    };
  },
};
</script>

<style>
.login-card {
  max-width: 600px;
  margin: auto;
}
</style>
