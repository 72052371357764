import Vue from "vue";
import VueRouter from "vue-router";
import ContactUsView from "@/views/ContactUsView.vue";
import LandingView from "@/views/LandingView.vue";
import OnlineSafetyView from "@/views/OnlineSafetyView.vue";
import DashboardView from "@/views/DashboardView.vue";
// import SubscriptionPlanView from "@/views/SubscriptionPlanView.vue";
import ApiKeysView from "@/views/ApiKeysView.vue";
import ActivateLicence from "@/views/Retail/ActivateLicence.vue";
import ActivateLicenceV2 from "@/views/Retail/ActivateLicenceV2.vue";

import RetailSubscriptionsView from "@/views/Retail/RetailSubscriptionsView.vue";

import ReportsView from "@/views/ReportsView.vue";
import AlertsView from "@/views/AlertsView.vue";
import LoginView from "@/views/LoginView.vue";
import ProfileView from "@/views/ProfileView.vue";
import SuccessfulPaymentView from "@/views/SuccessfulPaymentView.vue";
import CheckoutView from "@/views/CheckoutView.vue";
import ForgottenPasswordView from "@/views/ForgottenPasswordView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import RetailActivateView from "@/views/Retail/RetailActivateView.vue";
import DeleteAccountView from "@/views/DeleteAccountView.vue";
import UserApprovalView from "@/views/UserApprovalView.vue";
import PricingPlansView from "@/views/PricingPlansView.vue";

import RetailBillingSuccess from "@/views/Retail/RetailBillingSuccess.vue";
import RetailLoginView from "@/views/Retail/RetailLoginView.vue";

import RetailBillingCancelled from "@/views/Retail/RetailBillingCancelled.vue";
import RetailSelfServiceLicenceList from "@/views/Retail/RetailSelfServiceLicenceList.vue";
import SignupView from "@/views/SignupView.vue";
import RetailSignupView from "@/views/Retail/RetailSignupView.vue";
import RetailLandingView from "@/views/Retail/RetailLandingView.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },

  {
    name: "LandingView",
    path: "/home",
    component: LandingView,
  },
  {
    name: "RetailLandingView",
    path: "/retail-home",
    component: RetailLandingView,
  },

  {
    name: "OnlineSafetyView",
    path: "/home/online-safety",
    component: OnlineSafetyView,
  },
  {
    name: "ContactUsView",
    path: "/contact-us",
    component: ContactUsView,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    name: "ApiKeys",
    path: "/api-keys",
    component: ApiKeysView,
    meta: { requiresAuth: true },
  },
  {
    name: "ActivateLicence",
    path: "/activate-licence",
    component: ActivateLicence,
    meta: { requiresAuth: true },
  },
  {
    name: "UserApprovalView",
    path: "/user-approval",
    component: UserApprovalView,
    meta: { requiresAuth: false },
  },
  {
    name: "ActivateLicenceV2",
    path: "/activate-licence-v2",
    component: ActivateLicenceV2,
    meta: { requiresAuth: true },
  },
  {
    name: "Checkout",
    path: "/checkout/:token",
    component: CheckoutView,
    meta: { requiresAuth: true },
  },
  // {
  //   name: "SubscriptionPlanView",
  //   path: "/plans",
  //   component: SubscriptionPlanView,
  //   meta: { requiresAuth: true },
  // },
  {
    name: "ReportsView",
    path: "/reports",
    component: ReportsView,
    meta: { requiresAuth: true },
  },
  {
    name: "AlertsView",
    path: "/alerts",
    component: AlertsView,
    meta: { requiresAuth: true },
  },
  {
    name: "ProfileView",
    path: "/profile",
    component: ProfileView,
    meta: { requiresAuth: true },
  },
  {
    name: "DeleteAccountView",
    path: "/account/delete",
    component: DeleteAccountView,
    meta: { requiresAuth: true },
  },
  {
    name: "ActivateLoginView",
    path: "/login/:token",
    component: LoginView,
    meta: { guest: true },
  },
  {
    name: "RetailLoginView",
    path: "/retail-login",
    component: RetailLoginView,
    meta: { guest: true },
  },
  {
    name: "RetailActivateView",
    path: "/activate/:token",
    component: RetailActivateView,
    meta: { guest: true },
  },
  {
    name: "ResetPasswordView",
    path: "/reset-password/:token",
    component: ResetPasswordView,
    meta: { guest: true },
  },
  {
    name: "RetailBillingSuccess",
    path: "/retail-billing-success",
    component: RetailBillingSuccess,
    meta: { guest: true },
  },
  {
    name: "RetailSelfServiceLicenceList",
    path: "/retail-selfservice-licencelist",
    component: RetailSelfServiceLicenceList,
  },
  {
    name: "RetailSubscriptionsView",
    path: "/retail-subscriptions",
    component: RetailSubscriptionsView,
  },

  {
    name: "RetailBillingCancelled",
    path: "/retail-billing-cancelled/",
    component: RetailBillingCancelled,
    meta: { guest: true },
  },
  {
    name: "ForgottenPassword",
    path: "/reset-password",
    component: ForgottenPasswordView,
    meta: { guest: true },
  },
  {
    name: "LoginView",
    path: "/login/",
    component: LoginView,
    meta: { guest: true },
  },
  {
    name: "SignupView",
    path: "/signup",
    component: SignupView,
    meta: { guest: true },
  },
  {
    name: "PricingPlansView",
    path: "/plans",
    component: PricingPlansView,
    meta: { guest: true },
  },
  {
    name: "RetailSignupView",
    path: "/retail-signup",
    component: RetailSignupView,
  },

  {
    name: "SuccessfulPaymentView",
    path: "/payment-success",
    component: SuccessfulPaymentView,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (
      store.getters.isAuthenticated &&
      to.name !== "RetailLandingView" &&
      to.name !== "RetailSignupView" &&
      to.name != "RetailActivateView" &&
      to.name != "RetailBillingSuccess" &&
      to.name != "RetailSelfServiceLicenceList" &&
      to.name != "RetailLoginView" &&
      to.name != "PricingPlansView"
    ) {
      console.log(to);
      next("/api-keys");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
